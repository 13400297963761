<template lang="pug">
div(class='font-bold' :class='shippingData.sameDay ? "text-[#2C8560]" : "text-black"') {{ shippingText }}
</template>

<script setup lang="ts">
const { shippingData } = defineProps<{ shippingData: ProductLine['shipping'] }>()

// “Ships FREE Today” or “Ships FREE Tomorrow” or getFreeShippingText()
const shippingText = computed(() => {
  // Early Out
  if (!shippingData) return ''
  // Not sameDay but is eligible for Free Shipping
  if (!shippingData.sameDay && shippingData.isEligibleFreeShipping) return getFreeShippingText(shippingData)

  // Ships free today
  const today = new Date()
  const free = shippingData.isEligibleFreeShipping ? ' FREE' : ''

  // Example cutoffTime: '2024-10-07T19:00:00Z'
  const cutoffToday = new Date(shippingData.sameDay?.cutoffTime)
  if (today < cutoffToday) return `Ships${free} Today`

  // Ships free tomorrow
  const tomorrow = new Date(shippingData.sameDay?.nextShipDate)
  // Amount of hours between now and the next ship date
  const remainingHours = Math.floor((tomorrow - today) / 1000 / 60 / 60)
  if (remainingHours < 24) return `Ships${free} Tomorrow`
})
</script>
